// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../components/Layout/index'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import CommonLanding from '../components/CommonLanding'
import messages from '../components/CommonLanding/messages/Test/Test.lang'
import SEO from '../components/seo'

/* eslint-disable max-len */
const caseGalleryExample = (
  <StaticImage src="../images/case-study-modern/test/Main.png" alt="" objectFit="contain" className="image" />
)
const caseGalleryExampleBig = (
  <StaticImage src="../images/case-study-modern/test/test-img-big.png" alt="" objectFit="contain" className="image" />
)
const quoteMan = (
  <StaticImage src="../images/case-study-modern/test/QuoteMan.png" alt="" objectFit="cover" className="imageCover" />
)
const contactImage = (
  <StaticImage src="../images/case-study-modern/Rebecca.png" alt="" objectFit="cover" className="imageCover" />
)
const womanStaring = (
  <StaticImage src="../images/case-study-modern/test/woman-staring-into-the-depth-of-your-soul.jpg" alt="" objectFit="contain" className="image" />
)
const sectionSmallImage = (
  <StaticImage src="../images/case-study-modern/test/section-small-img.png" alt="" objectFit="contain" className="image" />
)
const sectionColorBg = (
  <StaticImage src="../images/case-study-modern/test/section-bg-color.png" alt="" objectFit="cover" className="imageCover" />
)
const Tobias = (
  <StaticImage src="../images/case-study-modern/test/Tobias.png" alt="" objectFit="cover" className="imageCover" />
)
const landingHero = (
  <StaticImage src="../images/case-study-modern/test/landing-mainteaser.png" alt="" objectFit="cover" className="imageCover" />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const data = [
  {
    type: 'ScrollSpy',
    data: [
      {
        icon: 'icon-calendar-heart',
        title: messages.scrollSpy1,
        id: 'section1'
      },
      {
        icon: 'icon-calendar-heart',
        title: messages.scrollSpy2,
        id: 'section2'
      },
      {
        icon: 'icon-calendar-heart',
        title: messages.scrollSpy3,
        id: 'section3'
      },
      {
        icon: 'icon-calendar-heart',
        title: messages.scrollSpy4,
        id: 'section4'
      },
      {
        icon: 'icon-calendar-heart',
        title: messages.scrollSpy5,
        id: 'section5'
      },
      {
        icon: 'icon-calendar-heart',
        title: messages.scrollSpy6,
        id: 'section6'
      }
    ]
  },
  {
    type: 'HeroTeaser',
    modeSettings: {
      mode: 'dark',
      bgImg: landingHero,
      bgColour: 'white',
      overlay: 'dark'
    },
    title: messages.heroTitle,
    titleTag: 'h1',
    text: messages.heroText,
    buttons: [
      {
        label: messages.heroBtn1,
        anchor: 'section3',
        btnConf: {
          color: 'accent',
          iconRight: 'icon-arrow_03-down-medium'
        }
      },
      {
        label: messages.heroBtn2,
        anchor: 'section4',
        btnConf: {
          color: 'white',
          outline: true,
          iconRight: 'icon-arrow_03-down-medium'
        }
      }
    ],
    withContactForm: true,
    contactFormConf: {
      formHeadline: messages.formTitle,
      formImg: sectionSmallImage
    }
  },
  {
    type: 'Section',
    id: 'section1',
    title: messages.sectionTitle,
    text: messages.sectionText,
    buttonLink: '/contact/',
    buttonLabel: messages.sectionButtonLabel,
    galleryTitle: messages.sectionGalleryTitle,
    galleryAlignment: 'right',
    gallery: [
      {
        img: caseGalleryExample
      },
      {
        img: caseGalleryExampleBig
      },
      {
        img: caseGalleryExample
      },
      {
        img: caseGalleryExampleBig
      },
    ],
  },
  {
    type: 'Section',
    id: 'section2',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black'
    },
    layoutConf: {
      colsEmpty: true,
      hasHeader: true
    },
    title: messages.sectionTitle,
    tiles: {
      count: 4,
      data: [
        {
          icon: 'icon-compass',
          title: messages.tile1Title,
          text: messages.tile1Text
        },
        {
          icon: 'icon-process',
          title: messages.tile2Title,
          text: messages.tile2Text
        },
        {
          accentText: messages.tile3AccentText,
          title: messages.tile3Title,
          text: messages.tile3Text
        },
        {
          icon: 'icon-style',
          title: messages.tile4Title,
          text: messages.tile4Text
        },
        {
          icon: 'icon-consulting',
          title: messages.tile5Title,
          text: messages.tile5Text
        },
        {
          icon: 'icon-gaming',
          title: messages.tile6Title,
          text: messages.tile6Text
        }
      ]
    }
  },
  {
    type: 'Section',
    id: 'section3',
    layoutConf: {
      colsEmpty: true,
      hasHeader: true
    },
    title: messages.sectionTitle,
    tiles: {
      count: 3,
      data: [
        {
          icon: 'icon-arrow_03-right-medium',
          preTitle: messages.tile1PreTitle,
          title: messages.tile1Title,
          text: messages.tile1Text
        },
        {
          icon: 'icon-arrow_03-right-medium',
          preTitle: messages.tile2PreTitle,
          title: messages.tile2Title,
          text: messages.tile2Text
        },
        {
          icon: 'icon-arrow_03-right-medium',
          preTitle: messages.tile3PreTitle,
          title: messages.tile3Title,
          text: messages.tile3Text
        }
      ]
    }
  },
  {
    type: 'Section',
    id: 'section4',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black'
    },
    quotes: [
      {
        img: quoteMan,
        quote: messages.sectionQuoteText1,
        name: 'Max Mustermann',
        position: 'CEO',
        company: 'Fantasy Company GmbH',
      },
    ],
  },
  {
    type: 'Section',
    id: 'section5',
    layoutConf: {
      hasHeader: true,
      headerCentered: true,
      colsTop: true
    },
    title: messages.sectionTitle,
    text: messages.sectionText,
    galleryAlignment: 'left',
    gallery: [
      {
        img: womanStaring,
        clickable: false
      }
    ],
    faq: [
      {
        question: messages.q1,
        answer: messages.answer
      },
      {
        question: messages.q2,
        answer: messages.answer
      },
      {
        question: messages.q3,
        answer: messages.answer
      },
      {
        question: messages.q4,
        answer: messages.answer
      },
      {
        question: messages.q5,
        answer: messages.answer
      }
    ]
  },
  {
    type: 'Section',
    id: 'section6',
    modeSettings: {
      mode: 'dark',
      bgImg: sectionColorBg,
      bgColour: 'white',
      overlay: 'coloured'
    },
    layoutConf: {
      gallerySm: true
    },
    title: messages.sectionTitle,
    text: messages.sectionText,
    buttonLink: '/contact/',
    buttonLabel: messages.sectionButtonLabel,
    galleryTitle: messages.sectionGalleryTitle,
    galleryAlignment: 'left',
    gallery: [
      {
        img: sectionSmallImage,
        clickable: false,
        plain: true
      },
    ],
  },
  {
    type: 'Section',
    layoutConf: {
      colsEmpty: true,
      headerCentered: true,
      hasHeader: true
    },
    title: messages.sectionTitle,
    offerTiles: {
      count: 3,
      data: [
        {
          title: messages.offerTilesTitle1,
          text: messages.offerTilesText1,
          list: [
            messages.offerTilesListItem1,
            messages.offerTilesListItem2,
            messages.offerTilesListItem3,
            messages.offerTilesListItem4,
            messages.offerTilesListItem5,
            messages.offerTilesListItem6
          ],
          button: {
            link: '/contact/',
            label: messages.offerTilesBtnLabel
          }
        },
        {
          title: messages.offerTilesTitle2,
          text: messages.offerTilesText2,
          accent: true,
          note: {
            icon: 'icon-checklist',
            text: messages.offerTilesNote
          },
          list: [
            messages.offerTilesListItem1,
            messages.offerTilesListItem2,
            messages.offerTilesListItem3,
            messages.offerTilesListItem4,
            messages.offerTilesListItem5,
            messages.offerTilesListItem6,
            messages.offerTilesListItem7
          ],
          button: {
            link: '/contact/',
            conf: {
              outline: false,
              color: 'accent'
            },
            label: messages.offerTilesBtnLabel
          },
          badgeAccent: messages.offerTilesBadge
        },
        {
          title: messages.offerTilesTitle3,
          text: messages.offerTilesText3,
          note: {
            icon: 'icon-checklist',
            text: messages.offerTilesNote
          },
          list: [
            messages.offerTilesListItem1,
            messages.offerTilesListItem2,
            messages.offerTilesListItem3,
            messages.offerTilesListItem4,
            messages.offerTilesListItem5,
            messages.offerTilesListItem6,
            messages.offerTilesListItem7
          ],
          button: {
            link: '/contact/',
            label: messages.offerTilesBtnLabel
          }
        }
      ]
    },
    persons: {
      titleConf: {
        title: messages.sectionTitle,
        centered: true
      },
      data: [
        {
          img: Tobias,
          name: 'Dominic Merfert',
          position: 'Senior UX & Process Engineer',
          text: messages.personText
        },
        {
          img: Tobias,
          name: 'Tobias Heinrich',
          position: 'Senior UX/UI Designer',
          text: messages.personText
        },
        {
          img: Tobias,
          name: 'Patrick Pauluhn',
          position: 'UX/UI Lead',
          text: messages.personText
        },
        {
          img: Tobias,
          name: 'Dominik Köhler',
          position: 'Senior UX/UI Engineer',
          text: messages.personText
        }
      ]
    }
  },
  {
    type: 'HeroTeaser',
    title: messages.heroTitle,
    titleTag: 'div',
    text: messages.heroText,
    withContactForm: true
  },
  {
    type: 'FloatingCTA',
    img: contactImage,
  },
]

const CommonLandingTest = ({ styles }: Props) => {
  return (
    <Layout footer={<Footer modern />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title="Test title"
        description="Test description"
        nofollow
      />
      <CommonLanding data={data} />
    </Layout>
  )
}

export default applyStylingHoc({})<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CommonLandingTest)
