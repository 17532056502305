import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  sectionTitle: 'S-Markt und Mehrwert - Vertriebsstratege der Sparkassen-Finanzgruppe',
  sectionText: '<p>S-Markt und Mehrwert, Dienstleister und Partner für Vertriebs- und Marketingstrategien der Auftraggeber aus der Sparkassen-Finanzgruppe. Das Unternehmen strebte nach Sicherheit, Flexibilität, Effizienzsteigerung und Modernisierung, um seine Dienstleistungen attraktiver für die Sparkassen und deren Reiseprodukte zu gestalten und die Kundenzufriedenheit zu steigern.</p>',
  sectionListItem1Title: 'Initialprojekt und Beratung',
  sectionListItem1Text: 'Hier lag der Fokus auf der Entwicklung des Whitelabel-Systems, der Mandanten-Verwaltung und der Anpassungsmöglichkeiten für die Mandanten. Die Beratung und Schulungen des Kunden waren hierbei essenziell.',
  sectionListItem2Title: 'Initialprojekt und Beratung',
  sectionListItem2Text: 'Hier lag der Fokus auf der Entwicklung des Whitelabel-Systems, der Mandanten-Verwaltung und der Anpassungsmöglichkeiten für die Mandanten. Die Beratung und Schulungen des Kunden waren hierbei essenziell.',
  sectionListItem3Title: 'Initialprojekt und Beratung',
  sectionListItem3Text: 'Hier lag der Fokus auf der Entwicklung des Whitelabel-Systems, der Mandanten-Verwaltung und der Anpassungsmöglichkeiten für die Mandanten. Die Beratung und Schulungen des Kunden waren hierbei essenziell.',
  sectionButtonLabel: 'Kostenlos beraten lassen',
  sectionQuoteText1: 'Das ist ein wunderbares Zitat über die Zusammenarbeit mit freshcells. Jeder Kunde sollte in einer honeymoon phase abgefangen werden und ein positves statement abgeben.',
  sectionGalleryTitle: 'Impressionen',
  tile1PreTitle: '01',
  tile1Title: 'Optimierte Nutzerführung',
  tile1Text: 'Optimieren Sie den Userflow für eine intuitive Nutzererfahrung und höhere Zufriedenheit.',
  tile2PreTitle: '02',
  tile2Title: 'Conversion-Steigerung',
  tile2Text: 'Identifizieren Sie Schwachstellen im Conversion-Funnel und erhalten Sie Verbesserungstipps.',
  tile3PreTitle: '03',
  tile3Title: 'Stärkere Nutzerbindung',
  tile3AccentText: '55%',
  tile3Text: 'Schaffen Sie ein konsistentes Erlebnis, um Bindung, Wiederbesuchsrate und Identifizierung zu fördern.',
  tile4Title: 'Tiefgehende Benutzeranalyse',
  tile4Text: 'Detaillierte Analysen zeigen Nutzerverhalten und Optimierungspotenziale Ihrer digitalen Plattform.',
  tile5Title: 'Zielgerichtet agieren',
  tile5Text: 'Setzen Sie unsere Tipps um, um Schwachstellen zu beheben und Ihre Website zu verbessern.',
  tile6Title: 'Branchenrelevanz',
  tile6Text: 'Nutzen Sie unsere E-Commerce-Erfahrung, um Ihren digitalen Vertrieb zielgruppengerecht zu optimieren.',
  q1: 'Welche Aspekte werden bei einem UX/UI Audit bewertet?',
  q2: 'Kann ein UX/UI Audit auch für mobile Apps oder andere digitale Produkte durchgeführt werden?',
  q3: 'Wie oft sollte ein UX/UI Audit durchgeführt werden?',
  q4: 'Wie werden die Ergebnisse des Audits präsentiert und was enthalten die Empfehlungen?',
  q5: 'Kann ich die Ergebnisse und Empfehlungen des UX/UI Audits selbst umsetzen oder benötige ich dafür Unterstützung?',
  answer: 'Die Ergebnisse werden in einem detaillierten Bericht zusammengefasst, der klare Analysen und Handlungsempfehlungen enthält. Dieser Bericht beschreibt Schwachstellen, gibt Verbesserungsvorschläge und formuliert Prioritäten, die Ihnen helfen, gezielt Optimierungen vorzunehmen und die User Experience sowie die Conversions Ihrer Produkte zu verbessern.',
  offerTilesTitle1: 'Basic UX/UI Checkv',
  offerTilesTitle2: 'Insight Report',
  offerTilesTitle3: 'Ultimate Experience Audit',
  offerTilesText1: 'Ein grundlegender Check Ihrer digitalen Plattform, der Ihnen einen Überblick über die wichtigsten Optimierungspotentiale.',
  offerTilesText2: 'Durch zusätzliche Analysen der Zielgruppe erhalten wir ein detaillierteres Gesamtbild und können so noch gezieltere Empfehlungen aussprechen.',
  offerTilesText3: 'Ein umfassender Audit, der Ihre digitale Präsenz tiefgreifend analysiert. Ideal für Unternehmen, die eine maßgeschneiderte Optimierung benötigen.',
  offerTilesNote: 'Alle Leistungen aus dem Basic UX/UI Check',
  offerTilesListItem1: 'Erweiterte Analyse der Nutzerinteraktionen',
  offerTilesListItem2: 'Bewertung von Conversion-Potenzialen',
  offerTilesListItem3: 'Detaillierte Performance-Analyse',
  offerTilesListItem4: 'Best practice Analyse & Abgleich',
  offerTilesListItem5: 'Wettbewerbsvergleich',
  offerTilesListItem6: 'Priorisierte Handlungsempfehlungen',
  offerTilesListItem7: 'Roadmap mit allen Handlungsempfehlungen',
  offerTilesBtnLabel: 'Jetzt Angebot anfordern',
  offerTilesBadge: 'Empfehlung',
  personText: 'Senior UX/UI Designer, spezialisiert auf die Optimierung von Landingpages und E-Commerce-Plattformen.',
  scrollSpy1: 'Intro',
  scrollSpy2: 'Benefits',
  scrollSpy3: 'FAQ',
  scrollSpy4: 'Process',
  scrollSpy5: 'Packages',
  scrollSpy6: 'Team',
  heroTitle: 'Professionelle <b>UX/UI</b> Audits für digitalen Erfolg',
  heroText: 'Erkennen Sie ungenutzte Potenziale und verwandeln Sie Besucher in zufriedene Kunden. Unser UX/UI Audit liefert klare Empfehlungen, um Ihre digitale Plattform gezielt zu verbessern.',
  heroBtn1: 'Pakete entdecken',
  heroBtn2: 'Vorgehen verstehen',
  formTitle: 'Jetzt <b>kostenlos</b> zum Thema <b>UX/UI Audit</b> beraten lassen',
})
